export enum AnalyticEventAction {
  Clear = 'clear',
  SaveAndManage = 'save & manage',
  Save = 'save',
  Replace = 'replace',
  DisabledActivate = 'Disabled Activate',
  Activate = 'Activate',
  LogCall = 'Log Call',
  Snooze = 'Snooze',
  RequestReport = 'Request Report',
  Reopen = 'Reopen',
  PickUp = 'Pick Up',
  SendInvite = 'send invite',
  ResolveAll = 'Resolve All',
  TriageRules = 'Triage Rules',
  ResumeCall = 'Resume Call',
  Maximize = 'maximize',
  Minimize = 'minimize',
  CancelIcon = 'cancel icon',
  EditEpisode = 'Edit Episode',
  EndEpisode = 'End Episode',
  EditConnectedTickets = 'Edit Connected Tickets',
  PauseCallTimer = 'pause call timer',
  SaveDraft = 'save draft',
  Click = 'click',
  Select = 'select',
  Search = 'search',
  PasteFromClipboard = 'paste from clipboard',
  Expand = 'expand',
  Collapse = 'collapse',
  Show = 'show',
  Hide = 'hide',
  Open = 'open',
  Delete = 'delete',
  Cancel = 'cancel',
  SingleReport = 'single report',
  TurnOn = 'turn on',
  TurnOff = 'turn off',
  TabSelect = 'tab select',
  Descending = 'descending',
  Ascending = 'ascending',
  NoSort = 'no sort',
  Add = 'add',
  Update = 'update',
  Remove = 'remove',
  UpdateInterval = 'update interval',
  RemoveInterval = 'remove interval',
  AddInterval = 'add interval',
  SaveAndResolve = 'save and resolve',
  StartEpisode = 'Start Episode',
  Preselected = 'preselected',
  SearchedByName = 'searched by NAME',
  SearchedByMrn = 'searched by MRN',
  SearchedByTask = 'searched by TASK',
  SearchedByTasks = 'searched by TASKS',
  SearchedByLocations = 'searched by LOCATIONS',
  SearchedByProviders = 'searched by PROVIDERS',
  SearchedByTicketType = 'searched by TICKET TYPE',
  OptOut = 'Opt Out',
  OptIn = 'Opt In',
  Down = 'down',
  Up = 'up',
  Close = 'close',
  LoadMore = 'load more',
  SelectAllInPage = 'select all in page',
  RemoveAllInPage = 'remove all in page',
  SelectAllInTable = 'select all in table',
  RemoveAllInTable = 'remove all in table',
  MultiSelect = 'multiselect',
  RefreshUpdates = 'refresh updated',
  PageDropdown = 'page dropdown',
  NextPage = 'next page',
  PreviousPage = 'previous page',
  LastPage = 'last page',
  FirstPage = 'first page',
  FinishCall = 'Finish Call',
  SubmitCall = 'Submit Call',
  GenerateNewSmartSummary = 'generate new smart summary',
  AdvancedSearch = 'advanced search',
  ClearAll = 'clear all',
  RefineWithAdvancedSearch = 'refine with Advanced Search',
  Bold = 'bold',
  Italic = 'italic',
  BulletedList = 'bulleted list',
  NumberedList = 'numbered list',
  Undo = 'undo',
  Redo = 'redo',
  AddRule = 'Add Rule',
  EditRule = 'Edit Rule',
  BackdropClick = 'Backdrop Click',
  EscapeKey = 'Escape Key',
  Comment = 'comment'
}
