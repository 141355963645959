// @ts-strict-ignore
import { Typography } from '@mui/material';

import { observer } from 'mobx-react';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';
import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import { OperatorTicketSource, OperatorTicketUrgency } from 'models/OperatorTicket';
import Ticket from 'models/Ticket';

import { useVerticalOverflowDetector } from 'hooks/useVerticalOverflowDetector';

import CardStack from 'views/Widgets/CardStack';
import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import TicketRowIcon, { TicketRowIconType } from 'components/Ticket/TicketRow/TicketRowIcon';
import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';
import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { Text } from 'components/UIkit/atoms/Text';
import { TextProps } from 'components/UIkit/atoms/Text/Text';

import TicketCommentsAndContact from './CommentsAndCallback/TicketCommentsAndContact';
import { OPERATOR_TICKETS_DESCRIPTION_MAX_LINES, useTicketProviderLocation } from './shared';
import TicketCreatedBy from './TicketCreatedBy';
import TicketRenderer from './TicketRenderer';

export interface OperatorTicketRowProps {
  ticket: Ticket;
  className?: string;
  withPatientLink: boolean;
  ticketRefs?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
}
const OperatorTicketRow = ({
  ticket,
  className,
  withPatientLink,
  ticketRefs,
  ticketIndex,
  ticketSectionCurrentPage = 0
}: OperatorTicketRowProps) => {
  const { ticketActions: Actions, tab, hideActionButtons } = useTicketOverviewContext();
  const { ref: descriptionRef, isActive } = useVerticalOverflowDetector();
  const { isExpanded, toggle } = useTicketToggle(
    ticket.id,
    false,
    ticketSectionCurrentPage,
    isActive,
    tab
  );
  const { ticketTypesStore, ticketsStore } = useStores();
  const patient = usePatientModel(ticket.patientId);
  const { operatorTicket } = ticket;
  const { getOperatorTicketTypeById } = ticketTypesStore;
  const providerLocationText = useTicketProviderLocation(ticket);
  const isActionInProgress = useTicketNetworkLoading(ticket.id);

  const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);

  const ticketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);

  const getTitleText = () => {
    const [subTypeId] = operatorTicket.subTicketTypeIds;
    const subTypeNode = getOperatorTicketTypeById(subTypeId);

    return (
      <div>
        <span className="bold title-text">{subTypeNode?.fullName}</span>
        {providerLocationText && <span className="subtitle-text"> — {providerLocationText}</span>}
      </div>
    );
  };

  const getSummary = () => {
    const hasPatientOperatorTicketSummaryPrefix =
      operatorTicket.source === OperatorTicketSource.Patient && Boolean(operatorTicket.notes);

    const descriptionContainerOptionalProps: {} | Pick<TextProps, 'maxNumberOfLines'> = isExpanded
      ? {}
      : { maxNumberOfLines: OPERATOR_TICKETS_DESCRIPTION_MAX_LINES };

    return (
      <div>
        {getTitleText()}

        <Text
          variant="body2"
          {...descriptionContainerOptionalProps}
          component="div"
          ref={descriptionRef}
        >
          {hasPatientOperatorTicketSummaryPrefix && (
            <Typography variant="body1">Patient Request via App:</Typography>
          )}{' '}
          <HtmlContent>{operatorTicket.notes}</HtmlContent>
        </Text>

        <TicketSummaryButton
          onClick={toggle}
          isExpanded={isExpanded}
          items={{
            draft: ticket.draftCallCount,
            comment: ticket.commentCount,
            call: ticket.callCount,
            callAttempt: ticket.callAttemptsCount
          }}
        />
      </div>
    );
  };

  return (
    <CardStack
      isSelected={ticketSelected}
      isOpen={isExpanded}
      classes={{
        root: className,
        closed: 'report-row-closed'
      }}
      numberOfStackedItems={ticket.callsAndDraftsCount}
    >
      <TicketRenderer
        ticketRefsCallback={ticketRefs}
        ticketId={ticket.id}
        isLoading={isActionInProgress}
        icon={
          <TicketRowIcon
            type={
              operatorTicket.urgency === OperatorTicketUrgency.High
                ? TicketRowIconType.OPERATOR_HIGH
                : TicketRowIconType.OPERATOR_STANDARD
            }
          />
        }
        classes={{
          container: 'no-border'
        }}
        actions={
          <Actions
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        }
        summary={getSummary()}
        patient={patient}
        createdByText={<TicketCreatedBy ticket={ticket} />}
        withPatientLink={withPatientLink}
      >
        {isExpanded && (
          <TicketCommentsAndContact
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        )}
      </TicketRenderer>
      {TicketPatientCallsList({
        calls,
        patient,
        isLoadingCalls,
        ticket,
        hideActionButtons
      })}
    </CardStack>
  );
};

export default observer(OperatorTicketRow);
